'use strict';

const { queryFirst, addClass, removeClass, scrollTo } = require('../domUtil');
const { HIDDEN_CLASS } = require('../constants');
const backToTopButton = queryFirst('.back-to-top-icon');

if (backToTopButton) {
    window.addEventListener('scroll', () => {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
            removeClass(backToTopButton, HIDDEN_CLASS);
        } else {
            addClass(backToTopButton, HIDDEN_CLASS);
        }
    });

    backToTopButton.addEventListener('click', () => {
        scrollTo(0);
    });
}